import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsOverlay, Sidebar } from '@src/components';

export const Dashboard = memo(function Dashboard({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  // Temporary workaround - sidebar is fully collapsed after transition duration
  const [sidebarFullyCollapsed, setSidebarFullyCollapsed] = useState(true);
  const toggleSideBar = useCallback(() => {
    if (!sidebarCollapsed) {
      setTimeout(() => {
        setSidebarFullyCollapsed(!sidebarFullyCollapsed);
      }, 300);
    } else {
      setSidebarFullyCollapsed(!sidebarFullyCollapsed);
    }

    setSidebarCollapsed(!sidebarCollapsed);
  }, [sidebarCollapsed, sidebarFullyCollapsed]);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar
        sidebarFullyCollapsed={sidebarFullyCollapsed}
        sidebarCollapsed={sidebarCollapsed}
        toggleSideBar={toggleSideBar}
      />
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            onClick={toggleSideBar}
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">{t('general.action.openSidebar')}</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none" tabIndex={0}>
          {children}
          <NotificationsOverlay />
        </main>
      </div>
    </div>
  );
});
