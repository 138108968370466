import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlainlyCombobox } from '@src/components';
import { useGetDesigns, useGetProjects, useQueryParams } from '@src/hooks';
import { DesignCategory } from '@src/models';
import { AnyProjectItem, ProjectItem } from '@src/types';

export type ProjectComboboxProps =
  | {
      includeDesigns: true;
      designCategory?: DesignCategory;
      onChange: (item?: AnyProjectItem) => void;
      notSelectedLabel?: string;
      showNotAnalyzed: boolean;
    }
  | {
      includeDesigns: false;
      designCategory?: undefined;
      onChange: (item?: ProjectItem) => void;
      notSelectedLabel?: string;
      showNotAnalyzed: boolean;
    };

export const ProjectCombobox = ({
  onChange,
  notSelectedLabel,
  showNotAnalyzed,
  includeDesigns,
  designCategory
}: ProjectComboboxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchQuery, updateQueryParams } = useQueryParams();
  const { data: projects = [] } = useGetProjects();
  const { data: designs = [] } = useGetDesigns();
  const selectedProjectId = searchQuery.get('projectId');

  const combinedData: AnyProjectItem[] = useMemo(() => {
    const data: AnyProjectItem[] = [];

    projects
      .filter(p => showNotAnalyzed || p.analysis?.done)
      .forEach(project => data.push({ isDesign: false, id: project.id, item: project }));

    if (includeDesigns) {
      designs
        .filter(design => !designCategory || design.category === designCategory)
        .forEach(design => data.push({ isDesign: true, id: design.id, item: design }));
    }

    return data;
  }, [projects, showNotAnalyzed, designs, designCategory, includeDesigns]);

  const item = useMemo(() => combinedData.find(i => i.id === selectedProjectId), [combinedData, selectedProjectId]);

  useEffect(() => {
    if (item) {
      if (includeDesigns) {
        onChange(item);
      } else if (!item.isDesign) {
        onChange(item);
      }
    } else {
      onChange(undefined);
    }
  }, [includeDesigns, item, onChange]);

  const onSelectionChange = useCallback(
    (item?: AnyProjectItem) => {
      navigate({
        search: `${updateQueryParams(window.location, {
          projectId: item?.id,
          templateId: undefined,
          rerenderParams: undefined,
          rerenderOptions: undefined
        })}`
      });
    },
    [navigate, updateQueryParams]
  );

  const data = useMemo(
    () =>
      combinedData
        ?.sort((a, b) => {
          if (a.isDesign && !b.isDesign) {
            return 1;
          } else if (!a.isDesign && b.isDesign) {
            return -1;
          } else {
            return a.item.name.localeCompare(b.item.name);
          }
        })
        ?.map(i => ({
          badge: i.isDesign ? t('general.common.design') : undefined,
          label: i.item.name,
          item: i,
          selected: i.id === selectedProjectId
        })),
    [combinedData, selectedProjectId, t]
  );

  return (
    <PlainlyCombobox
      id="project-combobox"
      hasSearch
      notSelectedLabel={notSelectedLabel}
      data={data}
      onSelectionChange={onSelectionChange}
    />
  );
};
