import { useCallback, useMemo, useState } from 'react';

export const useValidateUrls = () => {
  const [invalidUrls, setInvalidUrls] = useState(new Map<string, string>());

  const handleInvalidUrls = useCallback(
    (key: string) => (url: string, valid: boolean) => {
      if (valid) {
        if (invalidUrls.has(key)) {
          const newInvalidUrls = new Map(invalidUrls);
          newInvalidUrls.delete(key);
          setInvalidUrls(newInvalidUrls);
        }
      } else {
        if (!invalidUrls.has(key)) {
          setInvalidUrls(prev => new Map(prev.set(key, url)));
        }
      }
    },
    [invalidUrls]
  );

  const urlsValid: boolean = useMemo(() => invalidUrls.size === 0, [invalidUrls]);
  return { urlsValid, handleInvalidUrls };
};
