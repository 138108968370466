import { useTranslation } from 'react-i18next';

import { Alert } from '@src/components/common';
import { VideoDataErrorCode } from '@src/models';

export const VideoDataError = ({
  message,
  code,
  className
}: {
  message: string;
  code: VideoDataErrorCode;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      type="error"
      alertContent={
        <div>
          <p className="font-semibold">{t('components.article.ArticleVideo.code', { context: code })}</p>
          <p>{message}</p>
        </div>
      }
      show
      className={className}
    />
  );
};
