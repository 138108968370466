import { Trans, useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ScriptType } from '@plainly/types';
import localizationHelper from '@src/i18n';
import { Script } from '@src/models';
import { isEmptyObj } from '@src/utils';

export type ScriptingDescriptionProps = {
  script: Script;
};

export default function ScriptingDescription({ script }: ScriptingDescriptionProps) {
  const { t } = useTranslation();

  const getDescription = () => {
    switch (script.scriptType) {
      case 'CROP':
        if (script.compStartsAtInPoint && script.compEndsAtOutPoint) {
          return (
            <p className="ml-4 text-sm text-gray-500">
              {t('components.project.template.layer.scripts.ScriptingDescription.descriptionCrop', { context: 'BOTH' })}
            </p>
          );
        } else if (script.compStartsAtInPoint) {
          return (
            <p className="ml-4 text-sm text-gray-500">
              {t('components.project.template.layer.scripts.CropScriptingForm.compStartsAtInPointName')}
            </p>
          );
        } else if (script.compEndsAtOutPoint) {
          return (
            <p className="ml-4 text-sm text-gray-500">
              {t('components.project.template.layer.scripts.CropScriptingForm.compEndsAtOutPointName')}
            </p>
          );
        }
        break;

      case 'EXTEND':
        return script.maxDuration ? (
          <p className="ml-4 text-sm text-gray-500">
            {t('components.project.template.layer.scripts.ScriptingDescription.descriptionExtend', {
              maxDuration: localizationHelper
                .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                .format(script.maxDuration)
            })}
          </p>
        ) : null;

      case ScriptType.IMAGE_SEQUENCE_LOADER:
      case ScriptType.LAYER_MANAGEMENT:
        return (
          <p className="ml-4 text-sm text-gray-500">
            <Trans
              i18nKey={'components.project.template.layer.scripts.ScriptingDescription.descriptionParameter'}
              tOptions={{ parameterName: script.parameterName }}
            >
              Using parameter
              <span className="prose prose-sm">
                <code>{script.parameterName}</code>
              </span>
            </Trans>
          </p>
        );

      case 'SET_DURATION':
        return (
          <p className="ml-4 text-sm text-gray-500">
            {t('components.project.template.layer.scripts.ScriptingDescription.descriptionSetDuration', {
              duration: localizationHelper
                .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                .format(script.duration)
            })}
          </p>
        );

      case 'SHIFT_IN':
        return (
          <Trans
            i18nKey={'components.project.template.layer.scripts.ScriptingDescription.scriptDescription'}
            tOptions={{
              target: script.shiftTarget,
              toPoint: t('components.project.common.toPoint', {
                context: script.shiftsTo
              }),
              overlap: localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.shiftOverlap)
            }}
          >
            <p className="ml-4 text-sm text-gray-500">{script.shiftTarget}</p>
            <p className="ml-4 text-xs font-light text-gray-500">
              {script.shiftsTo},{' '}
              {localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.shiftOverlap)}
            </p>
          </Trans>
        );

      case 'SHIFT_OUT':
        return (
          <Trans
            i18nKey={'components.project.template.layer.scripts.ScriptingDescription.scriptDescription'}
            tOptions={{
              target: script.shiftTarget,
              toPoint: t('components.project.common.toPoint', {
                context: script.shiftsTo
              }),
              overlap: localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.shiftOverlap)
            }}
          >
            <p className="ml-4 text-sm text-gray-500">{script.shiftTarget}</p>
            <p className="ml-4 text-xs font-light text-gray-500">
              {script.shiftsTo},{' '}
              {localizationHelper.forNumber({ maximumFractionDigits: 1 }).format(script.shiftOverlap)}
            </p>
          </Trans>
        );

      case 'TRIM_IN':
        return (
          <Trans
            i18nKey={'components.project.template.layer.scripts.ScriptingDescription.scriptDescription'}
            tOptions={{
              target: script.trimTarget,
              toPoint: t('components.project.common.toPoint', {
                context: script.trimsTo
              }),
              overlap: localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.trimOverlap)
            }}
          >
            <p className="ml-4 text-sm text-gray-500">{script.trimTarget}</p>
            <p className="ml-4 text-xs font-light text-gray-500">
              {script.trimsTo}, {localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.trimOverlap)}
            </p>
          </Trans>
        );

      case 'TRIM_OUT':
        return (
          <Trans
            i18nKey={'components.project.template.layer.scripts.ScriptingDescription.scriptDescription'}
            tOptions={{
              target: script.trimTarget,
              toPoint: t('components.project.common.toPoint', {
                context: script.trimsTo
              }),
              overlap: localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.trimOverlap)
            }}
          >
            <p className="ml-4 text-sm text-gray-500">{script.trimTarget}</p>
            <p className="ml-4 text-xs font-light text-gray-500">
              {script.trimsTo}, {localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.trimOverlap)}
            </p>
          </Trans>
        );

      case 'STRETCH':
        return script.stretchesTo !== 'end-of-comp' ? (
          <Trans
            i18nKey={'components.project.template.layer.scripts.ScriptingDescription.scriptDescription'}
            tOptions={{
              target: script.stretchTarget,
              toPoint: t('components.project.common.toPoint', {
                context: script.stretchesTo
              }),
              overlap: localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.stretchOverlap)
            }}
          >
            <p className="ml-4 text-sm text-gray-500">{script.stretchTarget}</p>
            <p className="ml-4 text-xs font-light text-gray-500">
              {script.stretchesTo},{' '}
              {localizationHelper.forNumber({ maximumFractionDigits: 0 }).format(script.stretchOverlap)}
            </p>
          </Trans>
        ) : (
          <p className="ml-4 text-sm text-gray-500">
            {t('components.project.common.toPoint', {
              context: script.stretchesTo
            })}
          </p>
        );

      case 'MEDIA_AUTO_SCALE': {
        const { fill, fixedRatio, transform } = script;

        const noEffect = !fill && !fixedRatio && isEmptyObj(transform?.size) && isEmptyObj(transform?.position);
        const fillAndFixed = fill && fixedRatio;
        const fillOnly = fill && !fixedRatio;
        const fixedOnly = !fill && fixedRatio;
        const transformSize = transform && transform?.size;
        const transformPosition = transform && transform?.position;

        return (
          <>
            {noEffect && (
              <p className="ml-4 flex items-center gap-1 text-sm text-gray-500">
                <ExclamationTriangleIcon className="size-5 text-yellow-400" />
                {t('components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale', {
                  context: 'noEffect'
                })}
              </p>
            )}
            {fillAndFixed ? (
              <p className="ml-4 text-sm text-gray-500">
                {t('components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale', {
                  context: 'fillAndFixed'
                })}
              </p>
            ) : (
              <>
                {fillOnly && (
                  <p className="ml-4 text-sm text-gray-500">
                    {t('components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale', {
                      context: 'fill'
                    })}
                  </p>
                )}
                {fixedOnly && (
                  <p className="ml-4 text-sm text-gray-500">
                    {t('components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale', {
                      context: 'fixedRatio'
                    })}
                  </p>
                )}
              </>
            )}
            {transformSize && (
              <p className="ml-4 text-sm text-gray-500">
                <Trans
                  i18nKey={'components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale'}
                  context="size"
                  tOptions={{
                    sizeX: transform.size?.sizeX,
                    sizeY: transform.size?.sizeY
                  }}
                >
                  Custom size
                  <span className="prose prose-sm">
                    <code>0px</code> x <code>0px</code>
                  </span>
                </Trans>
              </p>
            )}
            {transformPosition && (
              <p className="ml-4 text-sm text-gray-500">
                <Trans
                  i18nKey={'components.project.template.layer.scripts.ScriptingDescription.descriptionMediaAutoScale'}
                  context="position"
                  tOptions={{
                    positionX: transform.position?.posX,
                    positionY: transform.position?.posY
                  }}
                >
                  Position at
                  <span className="prose prose-sm">
                    <code>(0, 0)</code>
                  </span>
                </Trans>
              </p>
            )}
          </>
        );
      }

      case 'COMPOSITION_SET_SIZE': {
        return (
          <p className="ml-4 text-sm text-gray-500">
            <Trans
              i18nKey={'components.project.template.layer.scripts.ScriptingDescription.descriptionCompositionSetSize'}
              tOptions={{ width: script.width || 'Default', height: script.height || 'Default' }}
            >
              <span className="prose prose-sm prose-code:before:content-none prose-code:after:content-none">
                <code>0x0 px</code>
              </span>
            </Trans>
          </p>
        );
      }
    }
  };

  return <>{getDescription()}</>;
}
