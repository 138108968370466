import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useGetSubscription, useGetUserProfile } from './api';
import { useActiveOrganizationId } from './useActiveOrganizationId';

// A function that takes an `organizationId` string as a parameter and switches the active organization if the provided `organizationId` is different from the current active organization. It invalidates the queries, refetches the user profile, and refetches the subscription, so we get the proper UI updates.
export const useSwitchOrganization = () => {
  const queryClient = useQueryClient();
  const { refetch: refetchUser } = useGetUserProfile();
  const { refetch: refetchSubscription } = useGetSubscription();
  const { getActiveOrganizationId, setActiveOrganizationId, removeActiveOrganizationId } = useActiveOrganizationId();
  const navigate = useNavigate();

  const refetchAll = useCallback(() => {
    queryClient.invalidateQueries();
    refetchUser();
    refetchSubscription();
  }, [queryClient, refetchSubscription, refetchUser]);

  const switchOrg = useCallback(
    (organizationId: string, force = false, reload = false) => {
      const activeOrganizationId = getActiveOrganizationId();
      if (activeOrganizationId !== organizationId || force) {
        setActiveOrganizationId(organizationId);
        refetchAll();
        if (reload) {
          navigate('/');
        }
      }
    },
    [getActiveOrganizationId, navigate, refetchAll, setActiveOrganizationId]
  );

  const invalidate = useCallback(() => {
    removeActiveOrganizationId();
    refetchAll();
  }, [refetchAll, removeActiveOrganizationId]);

  return { switchOrg, invalidate };
};
