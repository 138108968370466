import { AeItemType, LayerEffectType } from '@plainly/types';

import { Script } from '..';

export enum LayerType {
  DATA = 'DATA',
  DATA_EFFECT = 'DATA_EFFECT',
  MEDIA = 'MEDIA',
  SOLID_COLOR = 'SOLID_COLOR',
  COMPOSITION = 'COMPOSITION'
}

export declare type Parametrization = {
  value: string;
  defaultValue?: string;
  expression: boolean;
  mandatory: boolean;
};

export declare type Scripting = {
  scripts: Script[];
};

export declare type CompositionIdentifier = {
  id: number;
  name: string;
  layers?: { name: string; type: AeItemType; layerName?: string }[];
};

export declare type AbstractLayer<T extends LayerType> = {
  layerType: T;
  internalId: string;
  label?: string;
  layerName: string;
  compositions: CompositionIdentifier[];
  parametrization?: Parametrization;
  scripting?: Scripting;
};

export declare type DataLayer = AbstractLayer<LayerType.DATA> & {
  propertyName: 'Source Text';
};

export declare type DataEffectLayer = AbstractLayer<LayerType.DATA_EFFECT> & {
  propertyName: string;
  effectType: LayerEffectType;
};

export declare type MediaLayer = AbstractLayer<LayerType.MEDIA> & {
  mediaType: 'image' | 'video' | 'audio';
};

export declare type SolidColorLayer = AbstractLayer<LayerType.SOLID_COLOR>;

export declare type CompositionLayer = AbstractLayer<LayerType.COMPOSITION>;

export declare type Layer = DataLayer | DataEffectLayer | MediaLayer | SolidColorLayer | CompositionLayer;
