import { memo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListBulletIcon, MagnifyingGlassIcon, TableCellsIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  Button,
  EmptyRenders,
  Loading,
  ProjectCombobox,
  RendersGrid,
  RendersTable,
  RenderStateCombobox,
  TemplateCombobox
} from '@src/components';
import {
  useAnyProjectItemReducer,
  useAppSettingsReducer,
  useGetInfiniteRenders,
  useQueryParams,
  View
} from '@src/hooks';
import { RenderState } from '@src/models';
import { isEmpty } from '@src/utils';

const PAGE_SIZE = 10;
const states = Object.values(RenderState);

export const RendersPreview = memo(function RendersPreview() {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { updateQueryParams, searchQuery } = useQueryParams();
  const navigate = useNavigate();
  const { settings, setView } = useAppSettingsReducer();

  const search = searchQuery.get('search') || '';

  const [state, updateProjectItem, updateRenderItem] = useAnyProjectItemReducer();
  const { projectItem: selectedProject, renderItem: selectedTemplate } = state;

  const renderState = searchQuery.get('state') || undefined;
  const selectedState = states.find(s => s === renderState);

  const {
    data: renders,
    refetch: fetchRenders,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isSuccess
  } = useGetInfiniteRenders({
    projectId: selectedProject?.id,
    templateId: selectedTemplate?.id,
    state: selectedState,
    size: PAGE_SIZE,
    searchTerm: search
  });

  return (
    <>
      <div className="flex-col space-y-6 md:flex">
        <div className="rounded-xl bg-gray-100 p-0 sm:bg-gray-200 sm:p-2">
          <div className="w-full space-x-0 space-y-2 lg:flex lg:space-x-1 lg:space-y-0">
            {!showSearch && (
              <>
                <div className="lg:w-1/3">
                  <ProjectCombobox
                    includeDesigns={true}
                    onChange={updateProjectItem}
                    notSelectedLabel={t('components.render.RenderPreview.filterByProject')}
                    showNotAnalyzed={false}
                  />
                </div>
                <div className="lg:w-1/3">
                  <TemplateCombobox
                    includeDesigns={true}
                    notSelectedLabel={t('components.render.RenderPreview.filterByTemplate')}
                    item={selectedProject}
                    onChange={updateRenderItem}
                  />
                </div>
                <div className="lg:w-1/3">
                  <RenderStateCombobox
                    notSelectedLabel={t('components.render.RenderPreview.filterByState')}
                    selectedState={selectedState}
                  />
                </div>
              </>
            )}
            <div className={classNames('grid grid-cols-3 space-x-1 lg:flex', showSearch && 'w-full gap-y-2')}>
              {showSearch && (
                <input
                  type="text"
                  name="search-term"
                  id="search-term"
                  className="col-span-3 block w-full flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                  placeholder="Search..."
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' && searchTerm !== '' && searchTerm !== search) {
                      navigate({
                        search: `?${updateQueryParams(window.location, {
                          projectId: '',
                          templateId: '',
                          state: '',
                          search: searchTerm
                        })}`
                      });
                    }
                    if (e.key === 'Escape') {
                      setShowSearch(false);
                      navigate({
                        search: `?${updateQueryParams(window.location, { search: '' })}`
                      });
                    }
                  }}
                  autoFocus
                />
              )}
              <Button
                secondary={!showSearch}
                disabled={showSearch && (searchTerm === '' || searchTerm === search)}
                onClick={() => {
                  if (!showSearch) {
                    setShowSearch(true);
                  } else {
                    navigate({
                      search: `?${updateQueryParams(window.location, {
                        projectId: '',
                        templateId: '',
                        state: '',
                        search: searchTerm
                      })}`
                    });
                  }
                }}
                icon={<MagnifyingGlassIcon />}
              >
                {t('general.action.search')}
              </Button>
              {showSearch && (
                <Button
                  secondary
                  className="mr-2"
                  onClick={() => {
                    setShowSearch(false);
                    navigate({
                      search: `?${updateQueryParams(window.location, { search: '' })}`
                    });
                  }}
                >
                  {t('general.action.cancel')}
                </Button>
              )}
              <Button
                title={t('components.render.RenderPreview.switchView', { context: settings.view })}
                secondary
                className="ml-0 sm:ml-1"
                onClick={() => setView(settings.view === View.Grid ? View.List : View.Grid)}
                icon={settings.view === View.Grid ? <ListBulletIcon /> : <TableCellsIcon />}
              >
                {t('components.render.RenderPreview.view', { context: settings.view })}
              </Button>
              {!showSearch && (
                <Button secondary onClick={() => fetchRenders()} icon={<ArrowPathIcon />} className="ml-0 sm:ml-1">
                  {t('general.action.update')}
                </Button>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {renders && !isEmpty(renders.pages?.[0]) ? (
              settings.view === View.Grid ? (
                <RendersGrid
                  renders={renders.pages}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isSuccess={isSuccess}
                />
              ) : (
                <RendersTable
                  renders={renders.pages}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isSuccess={isSuccess}
                />
              )
            ) : (
              <EmptyRenders projectId={selectedProject?.id} templateId={selectedTemplate?.id} />
            )}
          </>
        )}
      </div>
    </>
  );
});
