import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { AeItemType, AeMediaItemType } from '@plainly/types';
import { AeItemWithCompositions } from '@src/components';
import { MediaAeItem } from '@src/models';

enum SearchType {
  all = 'all',
  text = 'text',
  image = 'image',
  video = 'video',
  audio = 'audio',
  solid = 'solid',
  composition = 'composition'
}

const getFilterFunction = (type: SearchType, search?: string) => (item: AeItemWithCompositions) => {
  const { layer, compositions } = item;

  // make sure we are matching name or value if search is there
  if (search && search.length > 0) {
    const nameMatches = layer.name.toLowerCase().indexOf(search) > -1;
    const valueMatches = layer.value ? layer.value.toLowerCase().indexOf(search) > -1 : false;
    const compositionsMatches = compositions.find(c => c.join().toLocaleLowerCase().indexOf(search) > -1) !== undefined;
    if (!(nameMatches || valueMatches || compositionsMatches)) {
      return false;
    }
  }

  // then by type
  if (type === SearchType.text && layer.type !== AeItemType.TEXT) {
    return false;
  }
  if (type === SearchType.composition && layer.type !== AeItemType.COMPOSITION) {
    return false;
  }
  if (
    type === SearchType.image &&
    (layer.type !== AeItemType.MEDIA || (layer as MediaAeItem).mediaType !== AeMediaItemType.IMAGE)
  ) {
    return false;
  }
  if (
    type === SearchType.video &&
    (layer.type !== AeItemType.MEDIA || (layer as MediaAeItem).mediaType !== AeMediaItemType.VIDEO)
  ) {
    return false;
  }
  if (
    type === SearchType.audio &&
    (layer.type !== AeItemType.MEDIA || (layer as MediaAeItem).mediaType !== AeMediaItemType.AUDIO)
  ) {
    return false;
  }
  if (
    type === SearchType.solid &&
    (layer.type !== AeItemType.MEDIA || (layer as MediaAeItem).mediaType !== AeMediaItemType.SOLID)
  ) {
    return false;
  }

  return true;
};

export const LayerFilter = (props: { onChange: (f: (item: AeItemWithCompositions) => boolean) => void }) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const [type, setType] = useState<SearchType>(SearchType.all);

  const isSelectedType = (t: SearchType) => t === type;

  useEffect(() => {
    onChange(getFilterFunction(type, search));
  }, [onChange, search, type]);

  return (
    <div className="flex flex-col justify-between border-b border-t px-4 py-1 sm:px-6 lg:flex-row">
      <div className="mb-2 flex flex-1 border-b lg:mb-0 lg:border-b-0">
        <form className="flex w-full lg:ml-0" onSubmit={e => e.preventDefault()}>
          <label htmlFor="search_field" className="sr-only">
            {t('components.project.template.layer.LayerFilter.search')}
          </label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
              <MagnifyingGlassIcon className="h-5 w-5" />
            </div>
            <input
              onChange={e => setSearch(e.target.value.toLowerCase())}
              id="search_field"
              className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
              placeholder={t('components.project.template.layer.LayerFilter.search')}
              type="search"
              name="search"
            />
          </div>
        </form>
      </div>
      <div className="flex items-center justify-between overflow-auto py-1 text-sm md:ml-4 lg:ml-6">
        <span className="hidden text-gray-600 md:block">{t('components.common.typeTitle')}</span>
        {Object.values(SearchType).map(type => (
          <button
            key={type}
            onClick={() => setType(type)}
            className={classnames(
              'ml-0 rounded-lg px-2 py-1 focus-within:outline-none focus-within:ring-0 lg:ml-2',
              isSelectedType(type)
                ? 'bg-indigo-100 text-indigo-800'
                : 'text-indigo-600 hover:bg-indigo-50 hover:text-indigo-700'
            )}
          >
            {t('components.project.template.layer.LayerFilter.type', { context: `${type}` })}
          </button>
        ))}
      </div>
    </div>
  );
};
