import { PartialBy } from '@src/types';

import { ModelBaseWithDates, ResourceType } from '..';

export type User = ModelBaseWithDates & {
  name: string;
  email: string;
  emailVerified: boolean;
  defaultOrganizationId?: string;
  organizationIds: string[];
  picture?: string;
  nickname?: string;
  givenName?: string;
  familyName?: string;
};

export type Organization = ModelBaseWithDates & {
  name: string;
};

export enum OrganizationRole {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER'
}

export type UserOrganization = Organization & {
  memberSince: string;
  role: OrganizationRole;
};

export type UserDto = {
  user: User;
  organizations: UserOrganization[];
  productFruitsHmac?: string;
};

export type UserUpdateDto = {
  name?: string;
  organizationName?: string;
};

export type ApiKeyDto = PartialBy<CreateAndUpdateApiKeyDto, 'nickname'> & {
  id: string;
  key: string;
  expired: boolean;
};

export type CreateAndUpdateApiKeyDto = {
  nickname: string;
  expiryDate?: string;
  limits?: Partial<Record<ResourceType, number>>;
};

export type TeamMemberDto = {
  id: string;
  name: string;
  email: string;
  role: OrganizationRole;
};

export type OrganizationInvitation = ModelBaseWithDates & {
  email: string;
  organizationName: string;
  role: OrganizationRole;
  inviterEmail: string;
};

export type CreateInvitationDto = {
  email: string;
  role: OrganizationRole;
};

// value can be -> string | number | boolean | string[] | number[]
export type UserProductFruitsProps = {
  plainlyEnv: string;
  organizationsCount: number;
  subscriptionIdentifier: string;
  subscriptionStatus: string;
  subscriptionInternallyManaged: boolean;
  subscriptionStartedDate: string;
  hasProjectUploaded: boolean;
  hasProjectSuccessfullyAnalyzed: boolean;
  hasRender: boolean;
  hasRenderDone: boolean;
};
