import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { DynamicScriptType } from '@plainly/types';
import { DynamicScriptBatchRenderWrapper, DynamicScriptSingleRenderWrapper, FieldLabel } from '@src/components';
import { RenderParameters } from '@src/models';
import { SelectedDynamicScripts } from '@src/types';
import { isEmptyObj } from '@src/utils';

type BatchRender = {
  batchRender: true;
  onChange: (col: SelectedDynamicScripts) => void;
  selectedDynamicScripts: SelectedDynamicScripts;
  renderParameters?: never;
  columns: string[] | undefined;
};

type SingleRender = {
  batchRender: false;
  onChange: (
    parameterName: string,
    value: { [key: string]: string | number | boolean | readonly string[] | undefined }
  ) => void;
  selectedDynamicScripts?: never;
  renderParameters: RenderParameters | undefined;
  columns?: never;
};

type DynamicScriptProps = { parameterName: string; scriptType: DynamicScriptType; isComposition: boolean } & (
  | BatchRender
  | SingleRender
);

export const DynamicScriptSection = ({
  renderParameters,
  parameterName,
  scriptType,
  onChange,
  batchRender,
  selectedDynamicScripts,
  columns,
  isComposition
}: DynamicScriptProps) => {
  const hasValues = useMemo(() => {
    if (batchRender) {
      return Object.values(selectedDynamicScripts?.[parameterName] ?? {}).some(v => v.value !== undefined);
    } else {
      return (
        !isEmptyObj(renderParameters?.[parameterName]) &&
        Object.values(renderParameters?.[parameterName] ?? {}).every(v => v !== undefined)
      );
    }
  }, [batchRender, parameterName, renderParameters, selectedDynamicScripts]);

  const [expanded, setExpanded] = useState<boolean>();
  const showContent = expanded ?? hasValues;

  return (
    <div className={classNames(batchRender && 'col-span-full py-4 pl-4 pr-3')}>
      <DynamicScriptLabel
        setShowContent={setExpanded}
        showExpandHint={showContent}
        parameterName={parameterName}
        scriptType={scriptType}
        batchRender={batchRender}
      />
      {showContent && (
        <>
          {batchRender === true && (
            <DynamicScriptBatchRenderWrapper
              parameterName={parameterName}
              scriptType={scriptType}
              onChange={onChange}
              selectedDynamicScripts={selectedDynamicScripts}
              columns={columns}
              isComposition={isComposition}
            />
          )}
          {batchRender === false && (
            <DynamicScriptSingleRenderWrapper
              parameterName={parameterName}
              scriptType={scriptType}
              onChange={onChange}
              renderParameters={renderParameters}
              isComposition={isComposition}
            />
          )}
        </>
      )}
    </div>
  );
};

const DynamicScriptLabel = ({
  setShowContent,
  showExpandHint,
  parameterName,
  scriptType,
  batchRender = false
}: {
  setShowContent: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  showExpandHint: boolean | undefined;
  parameterName: string;
  scriptType: DynamicScriptType;
  batchRender: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() =>
        setShowContent(prev => {
          if (prev === undefined && showExpandHint === false) return true;
          if (prev === undefined && showExpandHint === true) return false;
          return !prev;
        })
      }
      className="group flex cursor-pointer items-start justify-between text-sm font-medium text-gray-900"
    >
      <div className={classNames(showExpandHint && 'mb-1')}>
        <FieldLabel
          name={t('components.project.template.layer.LayerParametrizeScriptingModal.name', {
            context: scriptType
          })}
          parameterKey={parameterName}
          className="cursor-pointer"
        />
        {!showExpandHint && !batchRender && (
          <p className="text-sm font-normal text-gray-500">{t('components.render.dynamicScripts.expandText')}</p>
        )}
      </div>
      {!showExpandHint && <ChevronDownIcon className="h-6 w-6 shrink-0 text-gray-500 group-hover:text-indigo-500" />}
      {showExpandHint && <ChevronUpIcon className="h-6 w-6 shrink-0 text-gray-500 group-hover:text-indigo-500" />}
    </div>
  );
};
