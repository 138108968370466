import { useMemo } from 'react';
import { decode } from 'js-base64';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getDynamicScripts, Header, RenderBatchForm, RenderBatchNavigation, ScrollingPage } from '@src/components';
import { useGetDesignDetails, useGetProjectDetails, useQueryParams } from '@src/hooks';
import { DesignParameter } from '@src/models';
import { RENDER_BATCH_CSV_DESIGN, RENDER_BATCH_CSV_PROJECT } from '@src/routes';
import { BatchRenderType } from '@src/types';
import { layerToParameter } from '@src/utils';

type RenderBatchCsvPageProps = {
  isDesign: boolean;
};

export const RenderBatchCsvPage = ({ isDesign }: RenderBatchCsvPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { searchQuery, withQueryParams } = useQueryParams();
  const defaultParameters = searchQuery.get('defaultParameters');

  const { step, projectId, templateId } = params as { step: string; projectId: string; templateId: string };
  const stepNum = parseInt(step) || 1;

  const { isLoading: loadingProject, data: project } = useGetProjectDetails(projectId, !isDesign);
  const { isLoading: loadingDesign, data: design } = useGetDesignDetails(projectId, isDesign);

  const template = project?.templates.find(t => t.id === templateId);
  const variant = design?.variants.find(v => v.id === templateId);

  const projectName = isDesign ? design?.name : project?.name;
  const templateName = isDesign ? variant?.name : template?.name;

  const parameters = isDesign
    ? design?.parameters
    : template?.layers
        .filter((l, index, array) => l.parametrization?.expression === true && array.indexOf(l) === index)
        .flatMap(l => layerToParameter(l) as DesignParameter);

  const previousStep = () => {
    navigate(
      withQueryParams(
        design
          ? generatePath(RENDER_BATCH_CSV_DESIGN, {
              ...params,
              step: (stepNum - 1).toString()
            })
          : generatePath(RENDER_BATCH_CSV_PROJECT, {
              ...params,
              step: (stepNum - 1).toString()
            }),
        { defaultParameters }
      )
    );
  };

  const nextStep = () => {
    navigate(
      withQueryParams(
        design
          ? generatePath(RENDER_BATCH_CSV_DESIGN, {
              ...params,
              step: (stepNum + 1).toString()
            })
          : generatePath(RENDER_BATCH_CSV_PROJECT, {
              ...params,
              step: (stepNum + 1).toString()
            }),
        { defaultParameters }
      )
    );
  };

  const subheader =
    design && variant
      ? t('components.render.RenderBatchCsvPage.subTitleDesign', {
          designName: design.name,
          designVariant: variant.id
        })
      : project && template
        ? t('components.render.RenderBatchCsvPage.subTitleProject', {
            projectName: project.name,
            templateName: template.name
          })
        : undefined;

  const decodedParams = defaultParameters && JSON.parse(decode(defaultParameters));
  const defaultRenderOptions = template?.defaultRenderOptions;

  const dynamicScripts = useMemo(() => getDynamicScripts(template), [template]);

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.RenderBatchPage', { type: 'CSV' })}</title>
        </Helmet>
        <Header header={t('components.common.batchRender')} subheader={subheader} />
        <RenderBatchNavigation activeStep={stepNum} context={BatchRenderType.csv} />
        <div className="space-y-6">
          <RenderBatchForm
            step={stepNum}
            previousStep={previousStep}
            nextStep={nextStep}
            parameters={parameters || []}
            defaultParameterValues={decodedParams}
            defaultRenderOptions={defaultRenderOptions}
            loading={loadingProject || loadingDesign}
            projectId={projectId}
            templateId={templateId}
            isDesign={isDesign}
            dynamicScripts={dynamicScripts}
            projectName={projectName}
            templateName={templateName}
          />
        </div>
      </ScrollingPage>
    </HelmetProvider>
  );
};
