import { ExtraOptions, OutputFormat, OutputFormatDto, PostEncode, RenderParameters, Webhook } from '@src/models';
import { convertDotsToNested, makeCurl, undefinedIfNull } from '@src/utils';

import { useGetApiKeys } from '../api/useOrganizationApi';

type RenderCurlProps = {
  projectId: string;
  templateId: string;
  parameters?: RenderParameters;
  outputFormat?: OutputFormat | OutputFormatDto;
  webhook?: Webhook;
  options?: ExtraOptions;
  attributes?: Record<string, unknown>;
};

export const useRenderCurl = ({
  projectId,
  templateId,
  parameters,
  outputFormat,
  webhook,
  options,
  attributes
}: RenderCurlProps) => {
  const { data: apiKeys = [] } = useGetApiKeys();
  const apiKey = apiKeys?.find(k => !k.expired)?.key;
  const url = `${import.meta.env.VITE_APP_BASE_URL}/api/v2/renders`;

  let body: RenderCurlProps = {
    projectId,
    templateId
  };

  if (parameters) {
    const convertedParameters = convertDotsToNested(parameters);
    body = { ...body, parameters: convertedParameters };
  }

  if (outputFormat) {
    const { attachment, attachmentFileName, outputModule, settingsTemplate, postEncoding } = outputFormat;
    if (attachment || attachmentFileName || outputModule || settingsTemplate || postEncoding) {
      let basePostEncoding: PostEncode | undefined;

      if (postEncoding?.type === 'captions') {
        basePostEncoding = postEncoding.baseEncoding;
      } else {
        basePostEncoding = postEncoding;
      }

      body = {
        ...body,
        outputFormat: {
          attachment: undefinedIfNull(attachment),
          attachmentFileName: undefinedIfNull(attachmentFileName),
          outputModule: undefinedIfNull(outputModule),
          settingsTemplate: undefinedIfNull(settingsTemplate),
          postEncoding: undefinedIfNull(basePostEncoding)
        }
      };
    }
  }

  if (webhook) {
    body = { ...body, webhook };
  }

  if (options) {
    body = { ...body, options };
  }

  if (attributes) {
    body = { ...body, attributes };
  }

  const jsonBody = JSON.stringify(body, null, 2);
  const curlWithApiKey = makeCurl(jsonBody, url, apiKey);
  const curlWithoutApiKey = makeCurl(jsonBody, url);

  return { curlWithApiKey, curlWithoutApiKey };
};
