import classNames from 'classnames';

import { RequiredMarker } from '@src/components/common';

export const FieldLabel = ({
  name,
  parameterKey,
  optional,
  description,
  className
}: {
  name: string;
  parameterKey: string;
  optional?: boolean;
  description?: string;
  className?: string;
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center gap-x-1">
        <label
          htmlFor={`${parameterKey}-field`}
          className={classNames('block text-sm font-medium text-gray-700', className)}
        >
          {name}
        </label>
        <p className="prose prose-sm break-all rounded-md bg-gray-100 px-1 prose-code:before:content-none prose-code:after:content-none">
          <code className="font-thin">#{parameterKey}</code>
        </p>
        {optional === false && <RequiredMarker />}
      </div>
      <p id={`${parameterKey}-description`} className="text-sm text-gray-500">
        {description}
      </p>
    </>
  );
};
