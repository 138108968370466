import { memo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { GlobeAltIcon, VideoCameraIcon } from '@heroicons/react/20/solid';
import { useGetRendersStats } from '@src/hooks';

import { StyledLink } from '../common';

export const RendersOverview = memo(function RendersOverview() {
  const { t } = useTranslation();
  const periods = [1, 7];
  const [selectedPeriod, setSelectedPeriod] = useState<number>(periods[0]);

  const { isLoading, data } = useGetRendersStats({ days: selectedPeriod });

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-5 xl:grid-cols-3">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('components.render.RendersOverview.title')}
          </h3>
          <p className="text-sm leading-6 text-gray-600">
            {isLoading
              ? t('components.render.RendersOverview.loading')
              : t('components.render.RendersOverview.description', { context: selectedPeriod })}
          </p>
        </div>
        <div className="mt-sm-0 mt-3">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t('general.action.selectATab')}
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={selectedPeriod}
              onChange={e => setSelectedPeriod(parseInt(e.target.value))}
            >
              <option key={periods[0]}>{t('components.render.RendersOverview.1Day')}</option>
              <option key={periods[1]}>{t('components.render.RendersOverview.7Days')}</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex justify-end space-x-4" aria-label="Tabs">
              <span
                key={periods[0]}
                onClick={() => setSelectedPeriod(periods[0])}
                className={classNames(
                  selectedPeriod === periods[0] ? 'bg-gray-200 text-gray-600' : 'text-gray-600 hover:text-gray-800',
                  'cursor-pointer rounded-md px-3 py-2 text-sm font-medium'
                )}
              >
                {t('components.render.RendersOverview.1Day')}
              </span>
              <span
                key={periods[1]}
                onClick={() => setSelectedPeriod(periods[1])}
                className={classNames(
                  selectedPeriod === periods[1] ? 'bg-gray-200 text-gray-600' : 'text-gray-600 hover:text-gray-800',
                  'cursor-pointer rounded-md px-3 py-2 text-sm font-medium'
                )}
              >
                {t('components.render.RendersOverview.7Days')}
              </span>
            </nav>
          </div>
        </div>
      </div>
      {isLoading && (
        <>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
            <div className="relative h-32 animate-pulse-tailwind overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" />
            <div className="relative h-32 animate-pulse-tailwind overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" />
          </dl>
        </>
      )}
      {!isLoading && data && (
        <>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <VideoCameraIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {t('components.render.RendersOverview.totalRenders')}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">{data.renders.total}</p>
                {data.renders.total > 0 && (
                  <p
                    className={classNames(
                      data.renders.failed === 0 ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold'
                    )}
                  >
                    {t('components.render.RendersOverview.failedWarning', { count: data.renders.failed })}
                  </p>
                )}
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <StyledLink to="/dashboard/renders"> {t('general.action.viewDetails')}</StyledLink>
                  </div>
                </div>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <GlobeAltIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {t('components.render.RendersOverview.webhookCalls')}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">{data.webhookCalls.total}</p>
                {data.webhookCalls.total > 0 && (
                  <p
                    className={classNames(
                      data.webhookCalls.failed === 0 ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold'
                    )}
                  >
                    {t('components.render.RendersOverview.failedWarning', { count: data.webhookCalls.failed })}
                  </p>
                )}
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="invisible text-sm">
                    <StyledLink to="/" className="invisible">
                      {' '}
                      {t('general.action.viewDetails')}
                    </StyledLink>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </>
      )}
    </div>
  );
});
