import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  AppmixerActiveIntegrationsCard,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CodeModal,
  CopyIdAction,
  CreatedByInformation,
  HeaderWithBreadcrumbs,
  Loading,
  LoadingProps,
  NotFound,
  RendersTimeline,
  StyledA,
  TemplateCheckMeta,
  TemplateDefaultRenderingOptions,
  TemplateDeleteAction,
  TemplateGenerateCsv,
  UpdatableString
} from '@src/components';
import {
  useCreateTemplateReducer,
  useEditProjectTemplate,
  useEditTemplateReducer,
  useGetProjectDetails,
  useQueryParams
} from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Project, Template } from '@src/models';
import * as routes from '@src/routes';

const LoadingTemplateDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

const TemplateInformation = ({ template, project }: { template: Template; project: Project }) => {
  const { state: createState, removeTemplate: removeTemplateCreate } = useCreateTemplateReducer();
  const { state: editState, removeTemplate: removeTemplateEdit } = useEditTemplateReducer();
  const { isLoading, mutateAsync: updateTemplate } = useEditProjectTemplate();
  const { refetch: refetchProject } = useGetProjectDetails(project.id);

  useEffect(() => {
    Object.entries(editState).forEach(([key, value]) => {
      if (key === template.id) {
        const valueWithoutLastModified = { ...value, lastModified: undefined };
        const templateWithoutLastModified = { ...template, lastModified: undefined };

        if (JSON.stringify(valueWithoutLastModified) === JSON.stringify(templateWithoutLastModified)) {
          removeTemplateEdit(key);
        }
      }
    });

    Object.values(createState).forEach(value => {
      if (value.name === template.name) {
        removeTemplateCreate(project.id);
      }
    });
  }, [project.id, removeTemplateCreate, removeTemplateEdit, editState, createState, template]);

  const { t } = useTranslation();
  const isDefault = template.id === project.defaultTemplateId;
  const sharingLink = project.sharingLinks && project.sharingLinks[template.id];

  const updateTemplateName = useCallback(
    async (value: string) => {
      const { data: freshProject, error } = await refetchProject();

      if (freshProject && !error) {
        const freshTemplate = freshProject.templates.find(t => t.id === template.id);

        if (freshTemplate) {
          const newTemplate = { ...freshTemplate, name: value };
          await updateTemplate({ projectId: freshProject.id, templateId: freshTemplate.id, template: newTemplate });
        }
      }
    },
    [refetchProject, template.id, updateTemplate]
  );

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="project-information-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.project.template.TemplateDetails.templateInformation')}
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.name')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <UpdatableString value={template.name} loading={isLoading} onUpdate={updateTemplateName} />
              <CopyIdAction className="mt-1" id={template.id} type="template" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.createdOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(template.createdDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.common.project')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <span>{project.name}</span>
              <CopyIdAction className="mt-1" id={project.id} type="project" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.lastModifiedOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(template.lastModified)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.common.createdBy')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <CreatedByInformation userId={template.createdBy} />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.project.common.defaultTemplate')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {isDefault ? t('general.common.yes') : t('general.common.no')}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t('components.project.template.common.renderingComposition')}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{template.renderingComposition}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t('components.project.template.TemplateDetails.templatedLayers')}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{template.layers.length}</dd>
          </div>
          {template.resolution && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.project.template.TemplateDetails.resolution')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {t('general.common.resolution', {
                  width: template.resolution.width,
                  height: template.resolution.height
                })}
              </dd>
            </div>
          )}
          {template.duration && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('general.common.durationLabel')}</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {t('general.common.duration', {
                  duration: localizationHelper.forNumber({ maximumFractionDigits: 1 }).format(template.duration)
                })}
              </dd>
            </div>
          )}
          {sharingLink && (
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.project.template.TemplateDetails.sharingLink')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <StyledA href={sharingLink} target="_blank" rel="noreferrer">
                  {sharingLink}
                </StyledA>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};

export type TemplateDetailsProps = {
  projectId: string;
  templateId: string;
};

export const TemplateDetails = ({ projectId, templateId }: TemplateDetailsProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();
  const { isLoading, data: project } = useGetProjectDetails(projectId);
  const template = project?.templates.find(t => t.id === templateId);
  const [showLayersModal, setShowLayersModal] = useState<boolean>(false);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'L') {
      setShowLayersModal(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      {isLoading && <LoadingTemplateDetails title={t('components.project.template.TemplateDetails.loading')} />}
      {!isLoading && (
        <>
          {!(project && template) && <NotFound title={t('components.project.template.common.empty')} />}
          {project && template && (
            <div className="space-y-6">
              <HeaderWithBreadcrumbs
                breadcrumbs={
                  <Breadcrumb>
                    <BreadcrumbItem to={routes.PROJECTS_LIST} label={t('general.common.projects')} />
                    <BreadcrumbItem to={generatePath(routes.PROJECT_DETAILS, { id: projectId })} label={project.name} />
                    <BreadcrumbItem
                      to={generatePath(routes.PROJECT_DETAILS, { id: projectId })}
                      label={t('general.common.templates')}
                    />
                    <BreadcrumbItem
                      to={generatePath(routes.PROJECT_TEMPLATE_DETAILS, { projectId, templateId })}
                      label={template.name}
                    />
                  </Breadcrumb>
                }
                header={template.name}
                actions={
                  <>
                    <Link to={generatePath(routes.PROJECT_TEMPLATE_EDIT, { projectId, templateId, step: '' })}>
                      <Button
                        secondary
                        disabled={!project.analyzed}
                        title={project.analyzed ? '' : t('components.project.template.TemplateDetails.editDisabled')}
                      >
                        {t('general.action.edit')}
                      </Button>
                    </Link>
                    <Link to={withQueryParams(routes.RENDER_FORM, { projectId, templateId })}>
                      <Button className="ml-2">{t('general.action.render')}</Button>
                    </Link>
                  </>
                }
              />
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                  <TemplateCheckMeta
                    projectId={projectId}
                    template={template}
                    showAs="alert"
                    enabled={project.analyzed}
                  />
                  <section aria-labelledby="template-information-title">
                    <TemplateInformation template={template} project={project} />
                  </section>
                  <section aria-labelledby="template-default-options">
                    <TemplateDefaultRenderingOptions template={template} projectId={projectId} />
                  </section>
                  <section aria-labelledby="template-active-integration">
                    <AppmixerActiveIntegrationsCard template={template} project={project} />
                  </section>
                  <section aria-labelledby="template-generate-csv">
                    <TemplateGenerateCsv template={template} projectName={project.name} />
                  </section>
                </div>
                <section aria-labelledby="render-title" className="lg:col-span-1 lg:col-start-3">
                  <RendersTimeline projectId={projectId} templateId={templateId} maxItems={6} />
                </section>
                <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                  <TemplateDeleteAction projectId={projectId} templateId={templateId} templateName={template.name} />
                </div>
              </div>
              <CodeModal
                showModal={showLayersModal}
                onClose={() => setShowLayersModal(false)}
                content={JSON.stringify(template.layers, undefined, 2)}
                showCopyAction
                size="md"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
