import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlainlyCombobox } from '@src/components/common';
import { useQueryParams } from '@src/hooks';
import { DesignVariant, Template } from '@src/models';
import { AnyProjectItem, AnyRenderItem, DesignItem, DesignVariantItem, ProjectItem, TemplateItem } from '@src/types';

export type TemplateComboboxProps =
  | {
      includeDesigns: true;
      item?: AnyProjectItem;
      onChange: (item?: AnyRenderItem) => void;
      notSelectedLabel?: string;
    }
  | {
      includeDesigns: false;
      item?: ProjectItem;
      onChange: (item?: TemplateItem) => void;
      notSelectedLabel?: string;
    };

export const TemplateCombobox = ({ includeDesigns, item, onChange, notSelectedLabel }: TemplateComboboxProps) => {
  const { searchQuery } = useQueryParams();
  const selectedId = searchQuery.get('templateId') || undefined;

  const onChangeVariant = useCallback(
    (variant?: DesignVariantItem): void => {
      if (includeDesigns && item?.isDesign) {
        onChange(variant);
      }
    },
    [item, includeDesigns, onChange]
  );

  const onChangeTemplate = useCallback(
    (template?: TemplateItem) => {
      if (!item?.isDesign) {
        onChange(template);
      }
    },
    [item, onChange]
  );

  return (
    <>
      {includeDesigns && item?.isDesign && (
        <DesignVariantCombobox
          selectedId={selectedId}
          notSelectedLabel={notSelectedLabel}
          designItem={item}
          onChange={onChangeVariant}
        />
      )}
      {!item?.isDesign && (
        <ProjectTemplateCombobox
          selectedId={selectedId}
          notSelectedLabel={notSelectedLabel}
          projectItem={item}
          onChange={onChangeTemplate}
        />
      )}
    </>
  );
};

const DesignVariantCombobox = ({
  selectedId,
  notSelectedLabel,
  designItem,
  onChange
}: {
  selectedId?: string;
  notSelectedLabel?: string;
  designItem?: DesignItem;
  onChange: (variant?: DesignVariantItem) => void;
}) => {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();
  const variants = useMemo(() => designItem?.item.variants || [], [designItem]);

  useEffect(() => {
    const variant = variants.find(v => v.id === selectedId);
    if (variant) {
      onChange({
        id: variant?.id,
        item: variant,
        isDesign: true
      });
    } else {
      onChange(undefined);
    }
  }, [variants, selectedId, onChange]);

  const onSelectionChange = (variant?: DesignVariant): void => {
    navigate({
      search: `?${updateQueryParams(window.location, {
        templateId: variant?.id,
        rerenderParams: undefined,
        rerenderOptions: undefined
      })}`
    });
  };

  return (
    <PlainlyCombobox
      id="template-combobox"
      hasSearch
      disabled={!designItem}
      notSelectedLabel={notSelectedLabel}
      data={variants
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(v => ({
          label: v.name,
          item: v,
          selected: v.id === selectedId
        }))}
      onSelectionChange={onSelectionChange}
    />
  );
};

const ProjectTemplateCombobox = ({
  selectedId,
  notSelectedLabel,
  projectItem,
  onChange
}: {
  selectedId?: string;
  notSelectedLabel?: string;
  projectItem?: ProjectItem;
  onChange: (template?: TemplateItem) => void;
}) => {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();
  const templates = useMemo(() => projectItem?.item.templates || [], [projectItem]);

  useEffect(() => {
    const template = templates?.find(t => t.id === selectedId);
    if (template) {
      onChange({
        id: template.id,
        item: template,
        isDesign: false
      });
    } else {
      onChange(undefined);
    }
  }, [templates, selectedId, onChange]);

  const onSelectionChange = useCallback(
    (template: Template | undefined): void => {
      navigate({
        search: `?${updateQueryParams(window.location, {
          templateId: template?.id,
          rerenderParams: undefined,
          rerenderOptions: undefined
        })}`
      });
    },
    [navigate, updateQueryParams]
  );

  const data = useMemo(
    () =>
      templates
        ?.sort((a: Template, b: Template) => a.name.localeCompare(b.name))
        .map(t => ({ label: t.name, item: t, selected: t.id === selectedId })),
    [selectedId, templates]
  );

  return (
    <PlainlyCombobox
      id="template-combobox"
      hasSearch
      disabled={!projectItem}
      notSelectedLabel={notSelectedLabel}
      data={data}
      onSelectionChange={onSelectionChange}
    />
  );
};
