import { cloneElement, isValidElement, memo, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import { MenuButton } from '@headlessui/react';
import { SignalIcon } from '@heroicons/react/20/solid';

export type ButtonProps = React.ComponentProps<'button'> & {
  loading?: boolean;
  secondary?: boolean;
  danger?: boolean;
  help?: boolean;
  small?: boolean;
  menu?: boolean;
  roundedFull?: boolean;
  icon?: ReactNode;
  rightIcon?: ReactNode;
};

export const Button = memo(function Button({
  className,
  secondary,
  danger,
  help,
  small,
  menu,
  roundedFull,
  type,
  disabled,
  loading,
  children,
  icon,
  rightIcon,
  ...rest
}: ButtonProps) {
  const primary = !secondary && !danger && !help;
  const classPrimary = 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 border-transparent text-white';
  const classSecondary = 'bg-white hover:bg-gray-50 border-gray-300 text-gray-700 ';
  const classDanger = 'bg-red-600 hover:bg-red-700 focus:ring-red-500 border-transparent text-white';
  const classHelp = 'bg-indigo-50 hover:bg-indigo-100 text-indigo-600';

  const content = (
    <>
      {icon &&
        !loading &&
        isValidElement(icon) &&
        cloneElement(icon as ReactElement, {
          className: classNames(small ? 'h-4 w-4' : 'h-5 w-5', secondary && 'text-gray-500', children && '-ml-0.5 mr-2')
        })}
      {loading && (
        <SignalIcon
          className={classNames('mr-3 animate-spin', secondary && 'text-gray-500', small ? 'h-4 w-4' : 'h-5 w-5')}
        />
      )}
      {children}
      {rightIcon &&
        !loading &&
        isValidElement(rightIcon) &&
        cloneElement(rightIcon as ReactElement, {
          className: classNames(small ? 'h-4 w-4' : 'h-5 w-5', secondary && 'text-gray-500', children && 'ml-2 -mr-1')
        })}
    </>
  );

  const props: React.ComponentPropsWithoutRef<'button'> = {
    ...rest,
    disabled,
    type: type || 'button',
    className: classNames(
      'inline-flex items-center justify-center border shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
      !small && 'font-medium',
      small ? 'px-2 py-1' : children ? 'px-4 py-2' : 'px-2 py-2',
      roundedFull ? 'rounded-full' : 'rounded-md',
      primary && classPrimary,
      secondary && classSecondary,
      danger && classDanger,
      help && classHelp,
      disabled && 'disabled:opacity-50 disabled:cursor-not-allowed',
      loading && 'animate-pulse-tailwind',
      className
    )
  };

  if (menu) {
    return <MenuButton {...props}>{content}</MenuButton>;
  } else {
    return <button {...props}>{content}</button>;
  }
});
