import { useState } from 'react';
import classnames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';

import {
  AdjustmentsHorizontalIcon,
  CheckBadgeIcon,
  EyeSlashIcon,
  FaceFrownIcon,
  HashtagIcon
} from '@heroicons/react/24/outline';
import {
  ChevronRightIcon,
  DocumentTextIcon,
  FolderIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  SwatchIcon,
  VideoCameraIcon
} from '@heroicons/react/24/solid';
import { AeItemType, AeMediaItemType } from '@plainly/types';
import { AudioPreviewer, ImagePreviewer, SolidPreviewer, TextPreviewer, VideoPreviewer } from '@src/components';
import { AeItem, CompositionAeItem, CompositionIdentifier, MediaAeItem, TextAeItem } from '@src/models';

const getIcon = (layer: AeItem<AeItemType>) => {
  const iconClassNames = 'shrink-0 mr-1.5 h-5 w-5 text-gray-400';
  if (layer.type === AeItemType.TEXT) {
    return <DocumentTextIcon className={iconClassNames} />;
  } else if (layer.type === AeItemType.COMPOSITION) {
    return <FolderIcon className={iconClassNames} />;
  } else {
    const mediaLayer = layer as MediaAeItem;
    switch (mediaLayer.mediaType) {
      case AeMediaItemType.IMAGE:
        return <PhotoIcon className={iconClassNames} />;

      case AeMediaItemType.VIDEO:
        return <VideoCameraIcon className={iconClassNames} />;

      case AeMediaItemType.AUDIO:
        return <SpeakerWaveIcon className={iconClassNames} />;

      case AeMediaItemType.SOLID:
        return <SwatchIcon className={iconClassNames} />;
    }
  }
};

const getPreviewer = (
  layer: AeItem<AeItemType>,
  projectId: string,
  onPreviewChange: (previewActive: boolean) => void
) => {
  if (layer.type === AeItemType.MEDIA) {
    const mediaLayer = layer as MediaAeItem;
    if (mediaLayer.mediaType === AeMediaItemType.IMAGE && mediaLayer.value) {
      return (
        <ImagePreviewer
          projectId={projectId}
          value={mediaLayer.value}
          width={mediaLayer.width}
          height={mediaLayer.height}
          preview={mediaLayer.preview}
          onPreviewChange={onPreviewChange}
        />
      );
    } else if (mediaLayer.mediaType === AeMediaItemType.VIDEO && mediaLayer.value) {
      return (
        <VideoPreviewer
          projectId={projectId}
          value={mediaLayer.value}
          width={mediaLayer.width}
          height={mediaLayer.height}
          preview={mediaLayer.preview}
          onPreviewChange={onPreviewChange}
        />
      );
    } else if (mediaLayer.mediaType === AeMediaItemType.AUDIO && mediaLayer.value) {
      return (
        <AudioPreviewer
          projectId={projectId}
          value={mediaLayer.value}
          preview={mediaLayer.preview}
          onPreviewChange={onPreviewChange}
        />
      );
    } else if (mediaLayer.mediaType === AeMediaItemType.SOLID) {
      return <SolidPreviewer value={mediaLayer.value} />;
    }
  }
  return <TextPreviewer value={layer.value} />;
};

const getFont = (layer: AeItem<AeItemType>) => {
  if (layer.type === AeItemType.TEXT) {
    const textLayer = layer as TextAeItem;

    if (textLayer.font) {
      return (
        <p className="mt-1 truncate text-xs text-gray-500">
          <span className="truncate">{textLayer.font}</span>
        </p>
      );
    }
  }
  return null;
};

const getName = (layer: AeItem<AeItemType>) => {
  if (layer.type === AeItemType.COMPOSITION) {
    const compositionLayer = layer as CompositionAeItem;

    if (compositionLayer.layerName) {
      if (compositionLayer.layerName === compositionLayer.name) {
        return compositionLayer.name;
      } else {
        return `${compositionLayer.name} / ${compositionLayer.layerName}`;
      }
    }
  }

  return layer.name;
};

const getLayerInfoIcon = (layer: AeItem<AeItemType>, t: TFunction) => {
  return (
    <>
      {layer.enabled === false && (
        <EyeSlashIcon
          title={t('components.project.template.layer.LayerParametrizedListItem.disabled')}
          className="ml-1.5 h-5 w-5 shrink-0 cursor-help text-gray-500"
        />
      )}
      {layer.guideLayer && (
        <HashtagIcon
          title={t('components.project.template.layer.LayerParametrizedListItem.guideLayer')}
          className="ml-1.5 h-5 w-5 shrink-0 cursor-help text-gray-500"
        />
      )}
      {layer.adjustmentLayer && (
        <AdjustmentsHorizontalIcon
          title={t('components.project.template.layer.LayerParametrizedListItem.adjustmentLayer')}
          className="ml-1.5 h-5 w-5 shrink-0 cursor-help text-gray-500"
        />
      )}
      {layer.shy && (
        <FaceFrownIcon
          title={t('components.project.template.layer.LayerParametrizedListItem.shyLayer')}
          className="ml-1.5 h-5 w-5 shrink-0 cursor-help text-gray-500"
        />
      )}
    </>
  );
};

type LayerAvailableListItemProps<T extends AeItemType, I extends AeItem<T>> = {
  projectId: string;
  layer: I;
  compositions: CompositionIdentifier[][];
  onClick: () => void;
  parametrized?: boolean;
  disabled?: boolean;
};

export const LayerAvailableListItem = ({
  projectId,
  layer,
  compositions,
  onClick,
  parametrized,
  disabled
}: LayerAvailableListItemProps<AeItemType, AeItem<AeItemType>>) => {
  const [previewActive, setPreviewActive] = useState(false);
  const previewAwareOnClick = () => !previewActive && !disabled && onClick();
  const { t } = useTranslation();

  return (
    <li
      title={
        disabled
          ? t('components.project.template.layer.LayerParametrizedListItem.parametrizationNotSupported')
          : undefined
      }
    >
      <div
        onClick={previewAwareOnClick}
        className={classnames(
          'block',
          !previewActive && !disabled && 'cursor-pointer hover:bg-gray-50',
          disabled && 'cursor-not-allowed opacity-50'
        )}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="flex items-center truncate text-sm font-medium text-indigo-600">
                  {getIcon(layer)}
                  <span className="truncate">{getName(layer)}</span>
                  {parametrized && (
                    <CheckBadgeIcon
                      title={t('components.project.template.layer.LayerParametrizedListItem.parametrized')}
                      className="ml-1.5 h-5 w-5 shrink-0 cursor-help text-gray-500"
                    />
                  )}
                  {getLayerInfoIcon(layer, t)}
                </p>
                {compositions.map((c, index) => (
                  <p
                    key={`${layer.name}-${index}-comps`}
                    className={classnames('mt-1 flex items-center text-xs text-gray-500', index === 0 && 'md:mt-2')}
                  >
                    <span className="truncate">{c.map(cmp => cmp.name).join(' > ')}</span>
                  </p>
                ))}
              </div>
              <div className="mt-2 flex flex-col justify-between md:mt-0">
                {getPreviewer(layer, projectId, setPreviewActive)}
                {getFont(layer)}
              </div>
            </div>
          </div>
          {!disabled && (
            <div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
