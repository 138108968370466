import { useCallback } from 'react';

import { UserProductFruitsProps } from '@src/models';
import { State, useGlobalState } from '@src/state/store';
import { getProductFruitsBasicIdentification } from '@src/tools/productfruits';

export const useProductFruits = () => {
  const [user] = useGlobalState(State.USER);
  const [productFruitsHmac] = useGlobalState(State.PRODUCT_FRUITS_HMAC);

  const addCustomProductFruitsProps = useCallback(
    (props: Partial<UserProductFruitsProps>) => {
      if (window.productFruitsIsReady === true && user && productFruitsHmac) {
        try {
          // @ts-expect-error Property 'identifyUser' does not exist on type '{ api?: any; }'.
          window.productFruits?.identifyUser({
            ...getProductFruitsBasicIdentification(user),
            hmac: {
              hash: productFruitsHmac
            },
            props
          });
        } catch (error) {
          console.error('Error in productFruits.identifyUser', error);
        }
      }
    },
    [user, productFruitsHmac]
  );

  return { addCustomProductFruitsProps };
};
