import { Project, Render, RenderState, Subscription, User, UserDto, UserProductFruitsProps } from '@src/models';

export const getProductFruitsBasicIdentification = (user: User) => {
  return {
    username: user.id,
    email: user.email,
    firstname: user.givenName,
    lastname: user.familyName,
    signUpAt: user.createdDate
  };
};

export const getProductFruitsUserProps = (userDto: UserDto): Partial<UserProductFruitsProps> => {
  return {
    plainlyEnv: import.meta.env.VITE_APP_PLAINLY_ENV,
    organizationsCount: userDto.organizations.length
  };
};

export const getProductFruitsSubscriptionProps = (subscription: Subscription): Partial<UserProductFruitsProps> => {
  return {
    subscriptionIdentifier: subscription.subscriptionIdentifier,
    subscriptionStatus: subscription.status?.toLowerCase(),
    subscriptionInternallyManaged: subscription.internallyManaged,
    subscriptionStartedDate: subscription.startedDate
  };
};

export const getProductFruitsProjectsProps = (projects: Project[]): Partial<UserProductFruitsProps> => {
  const hasProjectUploaded = projects.length > 0;
  const hasProjectSuccessfullyAnalyzed = projects.some(({ analysis }) => analysis?.done);

  const result: Partial<UserProductFruitsProps> = {};

  // never reset back to false
  if (hasProjectUploaded) {
    result.hasProjectUploaded = true;
  }

  // never reset back to false
  if (hasProjectSuccessfullyAnalyzed) {
    result.hasProjectSuccessfullyAnalyzed = true;
  }

  return result;
};

export const getProductFruitsRendersProps = (render: Render[]): Partial<UserProductFruitsProps> => {
  const hasRender = render.length > 0;
  const hasRenderDone = render.some(({ state }) => state === RenderState.DONE);

  const result: Partial<UserProductFruitsProps> = {};

  // never reset back to false
  if (hasRender) {
    result.hasRender = true;
  }

  // never reset back to false
  if (hasRenderDone) {
    result.hasRenderDone = true;
  }

  return result;
};
