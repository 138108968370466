import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { CompositionSetSizeScript } from '@src/models';

export const CompositionSetSizeScriptingForm = ({
  setFormValid,
  setFormScript,
  editScript
}: {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: CompositionSetSizeScript | undefined) => void;
  editScript?: CompositionSetSizeScript;
}) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<{ width?: number; height?: number } | undefined>(
    editScript
      ? {
          width: editScript?.width,
          height: editScript?.height
        }
      : undefined
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const parsedValue = parseInt(value);
      const newInputs = { ...inputs, [name]: isNaN(parsedValue) ? undefined : parsedValue };
      setInputs(newInputs);

      const { width, height } = newInputs;

      if ((!width || width <= 0) && (!height || height <= 0)) {
        setFormValid(false);
        return;
      }

      setFormValid(true);
      setFormScript({ scriptType: ScriptType.COMPOSITION_SET_SIZE, width, height });
    },
    [inputs, setFormValid, setFormScript]
  );

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <div>
        <label htmlFor="width" className="block text-sm font-medium text-gray-700">
          {t('general.common.widthLabel')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.CompositionSetSizeScriptingForm.widthDescription')}
          </p>
        </label>
        <input
          id="width"
          name="width"
          type="number"
          step={1}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={handleChange}
          min={1}
          value={inputs?.width || ''}
        />
      </div>

      <div>
        <label htmlFor="height" className="block text-sm font-medium text-gray-700">
          {t('general.common.heightLabel')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.CompositionSetSizeScriptingForm.heightDescription')}
          </p>
        </label>
        <input
          id="height"
          name="height"
          type="number"
          step={1}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={handleChange}
          min={1}
          value={inputs?.height || ''}
        />
      </div>
    </div>
  );
};
