import { useCallback, useEffect } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useNavigate } from 'react-router-dom';

import { Dashboard } from '@src/components';
import { useError, useGetSubscription, useGetUserProfile } from '@src/hooks';
import { ErrorCode } from '@src/models';
import { ErrorPage, LoadingPage, UserMailDuplicatePage, UserNotVerifiedPage, UserPendingInvitePage } from '@src/pages';
import {
  getProductFruitsBasicIdentification,
  getProductFruitsSubscriptionProps,
  getProductFruitsUserProps
} from '@src/tools/productfruits';

export const DashboardWithUser: React.FunctionComponent = props => {
  const {
    isLoading: loadingUser,
    data: user,
    error: errorUser,
    refetch: refetchUser
  } = useGetUserProfile({ silentNotifications: true });
  const navigate = useNavigate();

  const { isApiErrorCode, formatMessage } = useError();

  const userNotVerified = errorUser && isApiErrorCode(errorUser, ErrorCode.USER_EMAIL_NOT_VERIFIED);
  const userHasPendingInvite = errorUser && isApiErrorCode(errorUser, ErrorCode.USER_ORGANIZATION_INVITE_PENDING);
  const userMailDuplicate = errorUser && isApiErrorCode(errorUser, ErrorCode.USER_EMAIL_DUPLICATE);

  const { isLoading: loadingSubscription, data: subscription, refetch: getSubscription } = useGetSubscription(!!user);

  const resolveInvite = useCallback(
    (accepted: boolean) => {
      // always refetch user after resolving invite
      refetchUser()
        // if he did not accept the invite, and we have the user, navigate to onboarding
        .then(updatedUser => {
          if (!accepted && updatedUser) {
            navigate('/onboarding');
          }
        });
    },
    [refetchUser, navigate]
  );

  // ensure subscription is loaded once we have a user
  // new users will be created on the get user call
  useEffect(() => {
    if (user && !subscription && !loadingSubscription) {
      getSubscription();
    }
  }, [getSubscription, loadingSubscription, subscription, user]);

  return (
    <>
      {loadingUser && <LoadingPage />}
      {!loadingUser && (
        <>
          {errorUser && (
            <>
              {userNotVerified && <UserNotVerifiedPage />}
              {userMailDuplicate && <UserMailDuplicatePage message={formatMessage(errorUser)} />}
              {userHasPendingInvite && <UserPendingInvitePage onResolve={resolveInvite} />}
              {!userNotVerified && !userHasPendingInvite && !userMailDuplicate && <ErrorPage error={errorUser!} />}
            </>
          )}
          {!errorUser && user && (
            <>
              {user.productFruitsHmac && (
                <ProductFruits
                  workspaceCode={import.meta.env.VITE_APP_PRODUCT_FRUITS_WORKSPACE}
                  language="en"
                  user={{
                    ...getProductFruitsBasicIdentification(user.user),
                    hmac: {
                      hash: user.productFruitsHmac
                    },
                    props: {
                      ...getProductFruitsUserProps(user),
                      ...(subscription ? getProductFruitsSubscriptionProps(subscription) : {})
                    }
                  }}
                />
              )}
              <Dashboard {...props} />
            </>
          )}
        </>
      )}
    </>
  );
};
