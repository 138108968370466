import { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';

export type AlertProps = React.ComponentProps<'div'> & {
  type: 'error' | 'warn' | 'info' | 'success';
  show: boolean;
  alertContent: string | React.ReactNode;
  onClose?: () => void;
};

export const Alert = ({ type, show, alertContent, onClose, className }: AlertProps) => {
  const { t } = useTranslation();
  const transitionClassNames = useMemo(() => {
    return classnames(
      'overflow-hidden rounded-md border p-4',
      type === 'error' && 'border-red-100 bg-red-50',
      type === 'warn' && 'border-yellow-100 bg-yellow-50',
      type === 'info' && 'border-blue-100 bg-blue-50',
      type === 'success' && 'border-green-100 bg-green-50',
      className
    );
  }, [className, type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'error':
        return <XCircleIcon className="h-5 w-5 text-red-400" />;
      case 'warn':
        return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />;
      case 'info':
        return <InformationCircleIcon className="h-5 w-5 text-blue-400" />;
      case 'success':
        return <CheckCircleIcon className="h-5 w-5 text-green-400" />;
    }
  }, [type]);

  const alertContentClassNames = useMemo(() => {
    return classnames(
      'text-sm font-medium',
      type === 'error' && 'text-red-800',
      type === 'warn' && 'text-yellow-800',
      type === 'info' && 'text-blue-800',
      type === 'success' && 'text-green-800'
    );
  }, [type]);

  const buttonClassNames = useMemo(() => {
    return classnames(
      'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
      type === 'error' && 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
      type === 'warn' &&
        'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50',
      type === 'info' && 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50',
      type === 'success' &&
        'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
    );
  }, [type]);

  return (
    <Transition
      as="div"
      className={transitionClassNames}
      show={show}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in duration-200 "
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex">
        <div className="shrink-0">{icon}</div>
        <div className="ml-3 w-full">
          {typeof alertContent === 'string' && <p className={alertContentClassNames}>{alertContent}</p>}
          {typeof alertContent !== 'string' && <div className={alertContentClassNames}>{alertContent}</div>}
        </div>
        {onClose && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button className={buttonClassNames} onClick={onClose}>
                <span className="sr-only">{t('general.action.dismiss')}</span>
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </Transition>
  );
};
