import { memo, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  Alert,
  AppmixerActiveIntegrations,
  AppmixerProvider,
  AppmixerTemplates,
  ComboboxItem,
  PlainlyCombobox,
  ProjectCombobox,
  TemplateCombobox
} from '@src/components';
import { useAnyProjectItemReducer, useQueryParams } from '@src/hooks';
import * as routes from '@src/routes';

const tabs = ['templates', 'active'] as const;

const IntegrationTabs = ({
  activeTab,
  projectId,
  templateId
}: {
  activeTab: 'templates' | 'active' | 'custom';
  projectId?: string;
  templateId?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { withQueryParams } = useQueryParams();
  const switchTab = (tab: string) => {
    navigate(withQueryParams(generatePath(routes.INTEGRATIONS_TAB, { tab }), { projectId, templateId }));
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t('general.action.selectATab')}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={'templates'}
          onChange={e => switchTab(e.target.value)}
        >
          {tabs.map(tab => (
            <option key={tab} value={tab}>
              {t('components.appmixer.AppmixerIntegrations.tab', { context: tab })}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(tab => (
              <button
                key={tab}
                onClick={() => switchTab(tab)}
                className={classNames(
                  tab === activeTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
                aria-current={tab === activeTab ? 'page' : undefined}
              >
                {t('components.appmixer.AppmixerIntegrations.tab', { context: tab })}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

const categories = [
  'communication',
  'content-management',
  'productivity',
  'storage',
  'social',
  'video-sharing',
  'ai'
] as const;
const types = ['delivery', 'invocation'] as const;

export const AppmixerIntegrations = memo(function AppmixerIntegrations({
  tab
}: {
  tab?: 'templates' | 'active' | 'custom';
}) {
  const { t } = useTranslation();

  // active tab
  const activeTab = tab || 'templates';

  // project & template selection
  const [state, updateProjectItem, updateRenderItem] = useAnyProjectItemReducer();
  const { projectItem: selectedProject, renderItem: selectedTemplate } = state;
  const [category, setCategory] = useState<string>();
  const [type, setType] = useState<string>();

  // our own callbacks
  const navigate = useNavigate();
  const { withQueryParams } = useQueryParams();
  const onIntegrationStarted = () => {
    navigate(
      withQueryParams(generatePath(routes.INTEGRATIONS_TAB, { tab: 'active' }), {
        projectId: selectedProject?.id,
        templateId: selectedTemplate?.id
      })
    );
  };

  const categoryItems: ComboboxItem<string>[] = useMemo(
    () =>
      categories.map(c => {
        return {
          label: t(`components.appmixer.AppmixerIntegrations.category`, { context: c }),
          item: c,
          selected: c === category
        };
      }),
    [category, t]
  );

  const typeItems: ComboboxItem<string>[] = useMemo(
    () =>
      types.map(typeItem => {
        return {
          label: t(`components.appmixer.AppmixerIntegrations.type`, { context: typeItem }),
          item: typeItem,
          selected: typeItem === type
        };
      }),
    [t, type]
  );

  return (
    <div id="appmixer-integrations" className="space-y-6">
      <div className="rounded-xl bg-gray-100 p-0 sm:bg-gray-200 sm:p-2">
        <div className="w-full space-x-0 space-y-2 md:flex md:space-x-1 md:space-y-0">
          <div className="md:w-1/4">
            <ProjectCombobox
              includeDesigns={true}
              showNotAnalyzed={false}
              onChange={updateProjectItem}
              notSelectedLabel={t('general.action.selectProject')}
            />
          </div>
          <div className="md:w-1/4">
            <TemplateCombobox
              includeDesigns={true}
              notSelectedLabel={t('general.action.selectTemplate')}
              item={selectedProject}
              onChange={updateRenderItem}
            />
          </div>
          <div className="md:w-1/4">
            <PlainlyCombobox
              notSelectedLabel={t('components.appmixer.AppmixerIntegrations.selectCategory')}
              data={categoryItems}
              onSelectionChange={setCategory}
              disabled={activeTab !== 'active' ? !selectedProject : false}
              hasSearch
            />
          </div>
          <div className="md:w-1/4">
            <PlainlyCombobox
              notSelectedLabel={t('components.appmixer.AppmixerIntegrations.selectType')}
              data={typeItems}
              onSelectionChange={setType}
              disabled={activeTab !== 'active' ? !selectedProject : false}
              hasSearch
            />
          </div>
        </div>
      </div>
      <IntegrationTabs activeTab={activeTab} projectId={selectedProject?.id} templateId={selectedTemplate?.id} />
      <AppmixerProvider>
        {activeTab !== 'active' && (
          <Alert
            type="info"
            show={!selectedProject}
            alertContent={t('components.appmixer.AppmixerIntegrations.selectProjectFirst')}
          />
        )}
        <AppmixerTemplates
          project={selectedProject?.item}
          template={selectedTemplate?.item}
          category={category}
          type={type}
          hidden={!selectedProject || activeTab !== 'templates'}
          onIntegrationStarted={onIntegrationStarted}
        />
        <AppmixerActiveIntegrations
          project={selectedProject?.item}
          template={selectedTemplate?.item}
          category={category}
          type={type}
          hidden={activeTab !== 'active'}
          fullScreen={true}
        />
      </AppmixerProvider>
    </div>
  );
});
